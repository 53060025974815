import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout";
import Seo from '../layout/seo';
import KnowledgeContent from "../content/knowledge/knowledgecontent";

class Knowledge extends React.Component {
  render() {
    const { data } = this.props;


    return (
      <Layout location={this.props.location}>
        <Seo lang="en" title="Knowledge" location={this.props.location} />
        <KnowledgeContent edges={data.allMarkdownRemark.edges} lang="en" />
      </Layout>)
  }
}

export default Knowledge;

export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___rank]}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              path
              title
              rank
              abstract
            }
          }
        }
      }
    }`

